<script setup>
const uniqueId = `id-${Math.random().toString(36).substr(2, 9)}`;
</script>

<template>
  <svg
    id="Sharp_Multi"
    data-name="Sharp Multi"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 118.6 181.64"
  >
    <defs>
      <linearGradient
        :id="`${uniqueId}-linear-gradient`"
        x1="139.67"
        y1="151.31"
        x2="89.25"
        y2="63.98"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.4" stop-color="#6a1dac" />
        <stop offset="1" stop-color="#b94fe9" />
      </linearGradient>
      <linearGradient
        :id="`${uniqueId}-linear-gradient-2`"
        x1="133.76"
        y1="220.55"
        x2="94.59"
        y2="152.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stop-color="#0d74ea" />
        <stop offset="1" stop-color="#6ce3fe" />
      </linearGradient>
      <linearGradient
        :id="`${uniqueId}-linear-gradient-3`"
        x1="96.87"
        y1="149.49"
        x2="46.46"
        y2="62.17"
        gradientTransform="translate(218.25 274.71) rotate(180)"
        :xlink:href="`#${uniqueId}-linear-gradient`"
      />
      <linearGradient
        :id="`${uniqueId}-linear-gradient-4`"
        x1="187.18"
        y1="228.31"
        x2="148.01"
        y2="160.46"
        gradientTransform="translate(314.47 284.92) rotate(180)"
        :xlink:href="`#${uniqueId}-linear-gradient-2`"
      />
    </defs>
    <title>logo_sharp</title>
    <path
      :fill="`url(#${uniqueId}-linear-gradient)`"
      class="cls-1"
      d="M109.54,65C121.62,53.15,133.66,49.87,133,48.14s-14.77-.22-28,6.83c-5.62,3-22,12.08-29.84,32.35a60.2,60.2,0,0,0-2.28,35.31c5.06,20.91,19.82,31.66,28.25,37.81,4,2.87,7.5,5.41,10.91,6.83L126.85,144l-13.79-8,.09-.16a34.25,34.25,0,0,1-5.43-3.7c-10.6-8.77-17.27-24.35-15.27-38.72C94.35,79.9,103.47,70.94,109.54,65Z"
      transform="translate(-71.22 -47.44)"
    />
    <path
      :fill="`url(#${uniqueId}-linear-gradient-2)`"
      class="cls-2"
      d="M158.7,190.18c-1-.75-5,6.68-15.27,10.93a33.11,33.11,0,0,1-18.68,2.28,33.53,33.53,0,0,1-17.31-9.11c-3.2-3.23-5.11-6.94-8.42-13.82h0L90.14,162h0l0-.05-.53.11v0L71.55,166a86,86,0,0,0,5.6,28.28c2,5.08,4.45,11.25,10.47,17.08,10,9.66,22,11,25.52,11.39s15.1,1.35,26.65-5.69C154.63,208,160.14,191.24,158.7,190.18Z"
      transform="translate(-71.22 -47.44)"
    />
    <path
      :fill="`url(#${uniqueId}-linear-gradient-3)`"
      class="cls-3"
      d="M147.89,140.63a35.47,35.47,0,0,1,5.44,3.7c10.59,8.77,17.26,24.35,15.26,38.73-1.89,13.57-11,22.52-17.08,28.47-12.08,11.85-24.12,15.12-23.47,16.86s14.78.22,28-6.84c5.63-3,22.05-12.07,29.84-32.34a60.2,60.2,0,0,0,2.28-35.31c-5.06-20.92-19.81-31.67-28.25-37.81-3.94-2.88-7.5-5.42-10.91-6.83l-14.83,23.25,13.79,8Z"
      transform="translate(-71.22 -47.44)"
    />
    <path
      :fill="`url(#${uniqueId}-linear-gradient-4)`"
      class="cls-4"
      d="M102.35,87c1,.75,5-6.69,15.27-10.94a33.2,33.2,0,0,1,18.68-2.28,33.57,33.57,0,0,1,17.31,9.12c3.2,3.23,5.11,6.93,8.42,13.81h0l8.86,18.49,0,0,0,0,.53-.11v0l18.07-3.95a85.93,85.93,0,0,0-5.6-28.27c-2-5.08-4.45-11.25-10.47-17.09-10-9.65-22.05-11-25.52-11.39a43,43,0,0,0-26.65,5.7C106.42,69.14,100.9,85.91,102.35,87Z"
      transform="translate(-71.22 -47.44)"
    />
  </svg>
</template>